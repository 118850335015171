<template>
  <div class="my">
    <van-cell
      title="账号信息"
      is-link
      :value="$store.state.user.profile.loginName"
      to="/accountInfo"
    />
    <van-cell
      title="个人信息"
      is-link
      :value="$store.state.user.profile.userName"
      to="/userInfo"
    />
    <van-cell
      title="消息提醒"
      is-link
      :value="$store.state.user.profile.switch.messageNotice ? '开' : '关'"
      to="/messageSwitch"
    />
    <van-cell
      title="更新日志"
      is-link
      to="/update"
    />
    <van-cell
      title="帮助中心"
      is-link
      url="https://is.shfas96119.com/word/is_help.pdf"
    />
    <van-cell
      title="关于系统"
      is-link
      to="/about"
    />
  </div>
</template>

<script>
export default {
  name: 'my',
  data() {
    return {}
  },

  methods: {
    clickHandler() {
      //   console.log(navigator.userAgent)
      //   if (navigator.userAgent.match(
      //     /(iPhone|iPod|ios|iPad)/i
      //   ) != null) {
      //     window.location.href = 'http://49.235.33.184/word/is_help.pdf'
      //   } else {
      //     window.location.href = 'https://is.shfas96119.com/word/is_help.pdf'
      // }
      window.location.href = 'https://is.shfas96119.com/word/is_help.pdf'
    }
  }
}
</script>
<style lang="scss" scoped>
.my {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .van-switch {
      font-size: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
